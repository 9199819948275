import { useAuth } from '@upper/auth'
import {
  DashboardEngagementsDocument,
  EngagementCardFragment,
  EngagementStatus,
  JobStatus,
  TalentKycStatus,
  TalentStatus,
  TemplatesDocument,
} from '@upper/graphql/freelancer'
import {
  CalendarIcon,
  DocumentIcon,
  InfoIcon,
  LongRightArrowIcon,
  MapIcon,
  RunningIcon,
} from '@upper/icons'
import { SkillTag, Spinner, Tooltip, classNames } from '@upper/ui'
import { formatDate, formatTime } from '@upper/utils'
import Head from 'next/head'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useQuery } from 'urql'
import { DashboardBannerManager } from '../components/dasboard-banner'
import EngagementDetails from '../components/engagement-details'
import JobDetails from '../components/job-details'
import MainLayout from '../components/main-layout'
import { useTalentStatus } from '../components/with-talent-status'
import { ENGAGEMENT_STATUS_LABELS } from '../lib/engagement'
import { Button } from '@upper/sapphire/ui'
import { HTMLAttributes } from 'react'
import { InboxIcon } from 'lucide-react'

export default function Dashboard() {
  const {
    talentStatus,
    isTalentActive,
    isTalentRejected,
    hasFreelancerAppAccess,
    verificationStatus,
  } = useTalentStatus()
  const [engagementsResult] = useQuery({
    query: DashboardEngagementsDocument,
    pause: isTalentRejected,
    variables: {},
  })
  const [viewJobId, setViewJobId] = React.useState<string | null>(null)
  const router = useRouter()
  const viewEngagementId = router.query.engagementId
    ? String(router.query.engagementId)
    : null

  const { user } = useAuth()

  const engagements = React.useMemo(() => {
    return (
      engagementsResult.data?.engagements?.filter(
        (e) =>
          e.job?.status === JobStatus.InStaffing ||
          [EngagementStatus.Hired, EngagementStatus.Ended].includes(
            e.latestRevision?.status ?? EngagementStatus.Inbox
          )
      ) ?? []
    )
  }, [engagementsResult.data?.engagements])

  if (engagementsResult.fetching) {
    return (
      <div className="flex h-80 items-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (engagementsResult.error) {
    return <div>Error: {engagementsResult.error.message}</div>
  }

  return (
    <>
      <Head>
        <title>Dashboard - Upper Freelancer</title>
      </Head>

      {![TalentStatus.Rejected, TalentStatus.Active].includes(
        talentStatus!
      ) && (
        <DashboardBannerManager
          status={talentStatus ?? TalentStatus.Unknown}
          verificationStatus={verificationStatus ?? TalentKycStatus.NotStarted}
          hasAppAcces={hasFreelancerAppAccess ?? false}
        />
      )}

      <div>
        {isTalentRejected ? (
          <div className="bg-red-lightest/40 text-red-darkest rounded-xl p-6">
            <h2 className="text-lg">
              We are sorry you didn’t meet the technical requirements for the
              job therefore you don’t have access to this area. <br />
              We do encourage you to try again in 6 months.
            </h2>
          </div>
        ) : (
          <>
            {engagements.length === 0 ? (
              <div className="flex flex-col items-center gap-5 rounded-2xl p-5 pt-40 text-center text-slate-600">
                <div className="text-slate-400">
                  <InboxIcon size={80} strokeWidth={1} absoluteStrokeWidth />
                </div>
                <div className="space-y-3">
                  <div className="space-y-1">
                    <h2 className="text-primary text-2xl font-semibold leading-none">
                      Hey, {user?.firstName}!
                    </h2>
                    <strong className="block font-medium">
                      You have no active jobs or applications at the moment.
                    </strong>
                  </div>
                  <Button
                    variant={'primary'}
                    onClick={() => {
                      router.push('/open-positions')
                    }}
                  >
                    View open positions
                  </Button>
                </div>
              </div>
            ) : (
              <section className="max-w-5xl space-y-10 md:mt-8">
                <h2 className="text-2xl font-semibold leading-none text-slate-800">
                  <span className="text-primary">Hey, {user?.firstName}!</span>{' '}
                  Your current jobs and applications
                </h2>
                <div className="mt-4 space-y-5 md:grid md:grid-cols-3 md:gap-5 md:space-y-0">
                  {engagements.map((engagement) => (
                    <EngagementCard
                      key={engagement.id}
                      engagement={engagement}
                      onClick={() => {
                        setViewJobId(engagement.job?.id ?? null)
                      }}
                    />
                  ))}
                </div>
              </section>
            )}
          </>
        )}
      </div>

      <JobDetails
        jobId={viewJobId}
        isTalentActive={isTalentActive}
        onClose={() => {
          setViewJobId(null)
        }}
      />

      {viewEngagementId && (
        <EngagementDetails
          engagementId={viewEngagementId}
          isTalentActive={isTalentActive}
          onClose={() => {
            router.replace(
              {
                query: {},
              },
              undefined,
              {
                scroll: false,
                shallow: true,
              }
            )
          }}
        />
      )}
    </>
  )
}

Dashboard.getLayout = (page: React.ReactElement) => (
  <MainLayout>{page}</MainLayout>
)

Dashboard.authenticate = true

function EngagementCard({
  engagement,
  onClick,
}: {
  engagement: EngagementCardFragment
  onClick: () => void
}) {
  const [templatesResult] = useQuery({
    query: TemplatesDocument,
    variables: {
      filters: {
        groups: [
          'rejection_upper',
          'rejection_fellow',
          'rejection_client',
          'rejection_talent',
        ],
        keys: engagement.latestRevision?.rejectionReasons || null,
      },
    },
    pause: engagement.latestRevision?.rejectionReasons?.length === 0,
  })
  const templates = React.useMemo(() => {
    return templatesResult.data?.templates
  }, [templatesResult])

  const rejected = templates
  const rejectedCommet = engagement?.latestRevision?.statusExtraExplanation

  return (
    <button
      type="button"
      className="flex w-full flex-col text-left"
      onClick={onClick}
    >
      <div className="w-full flex-1 rounded-t-md bg-white px-5 pb-3 pt-5">
        <h3 className="text-2xl font-medium leading-6">
          {engagement.job!.name}
        </h3>
        {engagement.job!.publicPageHeadline && (
          <p className="text-gray-dark mt-1 truncate text-sm">
            {engagement.job!.publicPageHeadline}
          </p>
        )}
        <dl className="mt-9 grid grid-cols-2 gap-x-6 gap-y-8 md:mt-14">
          <div className="relative">
            <dt>
              <RunningIcon className="text-gray absolute h-6 w-6" />
              <p className="text-gray ml-8 text-sm">Start date</p>
            </dt>
            <dd className="ml-8 mt-0.5 text-sm">
              {engagement.job!.startDate
                ? formatDate(engagement.job!.startDate)
                : 'ASAP'}
            </dd>
          </div>
          <div className="relative">
            <dt>
              <CalendarIcon className="text-gray absolute h-6 w-6" />
              <p className="text-gray ml-8 text-sm">Duration</p>
            </dt>
            <dd className="ml-8 mt-0.5 text-sm">{engagement.job!.length}</dd>
          </div>
          <div className="relative">
            <dt>
              <DocumentIcon className="text-gray absolute h-6 w-6" />
              <p className="text-gray ml-8 text-sm">Commitment</p>
            </dt>
            <dd className="ml-8 mt-0.5 text-sm">
              {engagement.job!.commitment}
            </dd>
          </div>
          <div className="relative">
            <dt>
              <MapIcon className="text-gray absolute h-6 w-6" />
              <p className="text-gray ml-8 text-sm">Location</p>
            </dt>
            <dd className="ml-8 mt-0.5 text-sm">{engagement.job!.location}</dd>
          </div>
        </dl>
        <div className="mt-8">
          <ul className="-ml-1 -mt-1 flex flex-wrap">
            {engagement.job!.requiredSkills?.map((skill) => (
              <li key={skill.id} className="ml-1 mt-1">
                <SkillTag>{skill.name}</SkillTag>
              </li>
            ))}
            {engagement.job!.niceToHaveSkills?.map((skill) => (
              <li key={skill.id} className="ml-1 mt-1">
                <SkillTag type="secondary">{skill.name}</SkillTag>
              </li>
            ))}
          </ul>
        </div>
        <p className="mt-4 text-sm">
          <span className="text-gray">Last update:</span>{' '}
          {formatDate(engagement.job!.updatedAt)} |{' '}
          {formatTime(engagement.job!.updatedAt)}
        </p>
      </div>
      <div
        className={classNames(
          'flex w-full items-center justify-between rounded-b-md px-5 py-2.5 text-white',
          engagement.latestRevision!.status === EngagementStatus.Hired &&
            '!bg-green',
          engagement.latestRevision!.status?.includes('Rejected') && 'bg-red',
          'bg-orange'
        )}
      >
        <div className="flex gap-3">
          <span className="text-xs font-bold uppercase">
            {ENGAGEMENT_STATUS_LABELS[engagement.latestRevision!.status!]}
          </span>
          {engagement?.latestRevision?.status?.includes('Rejected') && (
            <Tooltip
              className="!bg-red"
              arrowClassName="text-red"
              content={
                <div className="max-w-xs text-white">
                  {rejected?.map((r) => r.value)?.join(', ')}
                  {rejectedCommet && (
                    <>
                      <br />
                      <span className="opacity-60">{rejectedCommet}</span>
                    </>
                  )}
                </div>
              }
            >
              <InfoIcon />
            </Tooltip>
          )}
        </div>
        <LongRightArrowIcon className="h-5 w-5" />
      </div>
    </button>
  )
}

function ReportsIllustration(props: HTMLAttributes<SVGElement>) {
  return (
    <svg
      width="68"
      height="82"
      viewBox="0 0 68 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M66.0857 14.556C66.7381 15.2081 67.1047 16.0927 67.1049 17.0151V77.1647C67.1049 78.0871 66.7384 78.9719 66.0861 79.6242C65.4338 80.2765 64.5491 80.6429 63.6266 80.6429H4.48226C3.55977 80.6429 2.67506 80.2765 2.02276 79.6242C1.37046 78.9719 1.004 78.0871 1.004 77.1647V4.12118C1.004 3.19869 1.37046 2.31398 2.02276 1.66168C2.67506 1.00938 3.55977 0.642918 4.48226 0.642918H50.7431C51.6571 0.649535 52.5317 1.01563 53.1779 1.66205L66.0857 14.556Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.931 24.9907V63.2516H56.6701"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6127 35.4255H30.5692C31.0305 35.4255 31.4728 35.6088 31.799 35.9349C32.1251 36.2611 32.3083 36.7034 32.3083 37.1647V63.2516H21.8736V37.1647C21.8736 36.7034 22.0568 36.2611 22.3829 35.9349C22.7091 35.6088 23.1514 35.4255 23.6127 35.4255Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.004 45.8603H47.9605C48.4218 45.8603 48.8641 46.0435 49.1903 46.3697C49.5164 46.6958 49.6997 47.1382 49.6997 47.5994V63.2516H39.2649V47.5994C39.2649 47.1382 39.4481 46.6958 39.7743 46.3697C40.1004 46.0435 40.5428 45.8603 41.004 45.8603Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
